import React from 'react';
import { getUserIdentity } from '../../api/utils';
import { AppPermissions } from './PermissionContext';

export function PermissionReference({ children }: { children: React.ReactNode }) {
  const identity = getUserIdentity();

  const checkPermit = (permit: string): boolean => {
    return identity?.permissions.includes(permit);
  };

  return <AppPermissions.Provider value={{ checkPermit }}> {children} </AppPermissions.Provider>;
}

export const usePermission = () => React.useContext(AppPermissions);
