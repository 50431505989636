import { useQuery, useMutation, useQueryClient } from 'react-query';
import { apiClient } from './index';

interface AppsPageInfo {
  page: number;
  pageSize?: number;
  appName?: string;
}

const getApps = async ({ queryKey }: any) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
  const [_key, { page, pageSize, appName }] = queryKey;
  let query = `?page=${page}&per_page=${pageSize}`;
  query = appName ? `${query}&q=${appName}` : query;
  const { data } = await apiClient(true, {
    url: `/apps${query}`,
  });

  return data;
};

function useApps({ page = 1, pageSize = 20, appName = undefined }: AppsPageInfo) {
  return useQuery(['apps', { page, pageSize, appName }], getApps, { keepPreviousData: true });
}

const getAppById = async ({ queryKey }: any) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
  const [_key, { id }] = queryKey;
  const { data } = await apiClient(true, {
    url: `/app/${id}`,
  });

  return data.data;
};

function useAppById(id: string) {
  return useQuery(['apps', { id }], getAppById);
}

const addApp = async (params: any) => {
  const { data } = await apiClient(true, {
    method: 'POST',
    url: `/app`,
    data: params,
  });

  return data.data;
};

function useAddApp() {
  const queryClient = useQueryClient();
  return useMutation('addapp', addApp, {
    onSuccess: () => {
      queryClient.invalidateQueries('apps');
    },
  });
}

const updateApp = async (params: any) => {
  const { data } = await apiClient(true, {
    method: 'POST',
    url: params.url,
    data: params,
  });

  return data;
};

function useUpdateApp() {
  const queryClient = useQueryClient();
  return useMutation('updateapp', updateApp, {
    onSuccess: () => {
      queryClient.invalidateQueries('apps');
    },
  });
}

const changeAppStatus = async (params: any) => {
  const { data } = await apiClient(true, {
    method: 'PATCH',
    url: params.url,
  });

  return data;
};

function useChangeAppStatus() {
  const queryClient = useQueryClient();
  return useMutation('statusapp', changeAppStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries('apps');
    },
  });
}

const deleteApp = async (params: any) => {
  const { data } = await apiClient(true, {
    method: 'DELETE',
    url: `/app/${params}`,
  });

  return data;
};

function useDeleteApp() {
  const queryClient = useQueryClient();
  return useMutation('deleteapps', deleteApp, {
    onSuccess: () => {
      queryClient.invalidateQueries('apps');
    },
  });
}

interface AppsUserInfo {
  page: number;
  pageSize?: number;
  id?: string;
  customerType?: string;
}

const getAppUsers = async ({ queryKey }: any) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
  const [_key, { page, pageSize, id, customerType }] = queryKey;
  const { data } = await apiClient(true, {
    url: `/app/${id}/accounts?page=${page}&per_page=${pageSize}`,
  });

  return data;
};

function useAppUsers({ page, pageSize, id, customerType }: AppsUserInfo) {
  return useQuery(['apps', { page, pageSize, id, customerType }], getAppUsers, {
    keepPreviousData: true,
  });
}

const getUserAppAccounts = async ({ queryKey }: any) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
  const [_key, { appId, accountId }] = queryKey;
  const { data } = await apiClient(true, {
    url: `/app/${appId}/account/${accountId}`,
  });

  return data.data;
};

function useUserAppAccounts(appId: string, accountId: string) {
  return useQuery(['userAppAccounts', { appId, accountId }], getUserAppAccounts, {
    keepPreviousData: true,
  });
}

export {
  useApps,
  useAddApp,
  useDeleteApp,
  useUpdateApp,
  useAppById,
  useChangeAppStatus,
  useAppUsers,
  useUserAppAccounts,
};
