const validateMessages = {
  // eslint-disable-next-line no-template-curly-in-string
  required: '${label} is required!',
  types: {
    // eslint-disable-next-line no-template-curly-in-string
    email: '${label} is not a valid email!',
    // eslint-disable-next-line no-template-curly-in-string
    number: '${label} is not a valid number!',
    // eslint-disable-next-line no-template-curly-in-string
    text: '${label} is required!',
  },
};

const FormConfig = {
  validateMessages,
};

export { FormConfig };
