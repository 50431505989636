import { useMutation } from 'react-query';
import { createAuthProvider } from 'react-token-auth';
import { AuthStorageKey } from '../common/constants';
import { apiClient } from './index';

export interface TokenInterface {
  access_token: string;
  refresh_token: string;
}

interface LoginInterface {
  username: string;
  password: string;
}

const authenticate = async (params: LoginInterface) => {
  const { data } = await apiClient(false, {
    url: `/login`,
    method: 'POST',
    data: params,
  });

  return data.data;
};

const refresh = async (token: TokenInterface) => {
  const { data } = await apiClient(false, {
    url: `/refresh`,
    method: 'POST',
    headers: { Authorization: `Bearer ${token.refresh_token}` },
  });

  return data.data;
};

const revoke = async () => {
  const { data } = await apiClient(true, {
    url: `/logout`,
    method: 'POST',
  });

  return data;
};

const { useAuth, authFetch, login, logout } = createAuthProvider({
  getAccessToken: (session) => session.access_token,
  storage: localStorage,
  storageKey: AuthStorageKey,
  onUpdateToken: (token: TokenInterface) => refresh(token),
});

function useLogin() {
  return useMutation('login', authenticate, {
    onSuccess: (data) => {
      login(data);
    },
  });
}

function useLogout() {
  return useMutation('revoke', revoke, {
    onSuccess: () => {
      logout();
    },
  });
}

export { useAuth, authFetch, useLogin, useLogout, login };
