import { ConfigProvider } from 'antd';
import React, { FC } from 'react';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { useAuth } from '../../api/auth';
import { queryClient } from '../../api/base';
import { useSystemInfo } from '../../api/systeminfo';
import { Login } from '../../pages/Login/Login';
import { PageLayout } from '../Layout/Layout';
import { PermissionReference } from '../Permissions/PermissionProvider';
import { Routes } from '../Routes/Routes';
import { FormConfig } from './GlobalConfig';

const AggregatorPages: FC = () => {
  const systemInfo = useSystemInfo();

  if (systemInfo.isSuccess) {
    window.less.modifyVars({
      '@primary-color': systemInfo?.data?.color_code || 'blue',
    });
  }

  return (
    <PermissionReference>
      <PageLayout theme="light">
        <Routes />
      </PageLayout>
    </PermissionReference>
  );
};

const AuthenticatedApp: FC = () => {
  const [logged] = useAuth();

  if (logged) {
    return <AggregatorPages />;
  }

  return <Login />;
};

const App: FC = () => (
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <ConfigProvider form={FormConfig}>
        <AuthenticatedApp />
      </ConfigProvider>
    </BrowserRouter>
  </QueryClientProvider>
);

export { App };
