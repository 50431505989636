import { UserOutlined, EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import { Form, Input, Button, Typography, Row, Col, Card, Space, Divider } from 'antd';
import React, { FC, useState } from 'react';
import { useLogin } from '../../api/auth';
import logo from '../../assets/images/icons/logo.jpg';
import { Page } from '../../components/Page/Page';
import classes from './Login.module.scss';

const { Text } = Typography;

export const Login: FC = () => {
  const [validation, setValidation] = useState(null);
  const login = useLogin();

  const onSubmit = (values: any) => {
    login.mutate(values, {
      onError: (error: any) => {
        setValidation(error.response.data.error);
      },
    });
  };

  const resetStates = () => {
    setValidation(null);
  };

  const LoginForm: FC = () => {
    return (
      <Form
        name="userLogin"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={onSubmit}
        onChange={resetStates}
      >
        <Form.Item
          name="username"
          rules={[{ required: true, message: 'Please input your Username!' }]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            id="username"
            placeholder="Username"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your Password!' }]}
        >
          <Input.Password
            placeholder="Password"
            id="password"
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
        </Form.Item>
        <Form.Item hidden={validation === null}>
          <div>
            <Text type="danger" id="login_error">
              {validation}
            </Text>
          </div>
        </Form.Item>
        <Form.Item>
          <Space>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              id="login_button"
            >
              Log in
            </Button>
          </Space>
        </Form.Item>
      </Form>
    );
  };

  return (
    <Page metaDescriptionContent="Login" title="Login">
      <Row className={classes.loginpage}>
        <Col span={8}>
          <div className={classes.advertise}>
            <Card cover={<img alt="APBS Logo" src={logo} />}>
              <Card.Meta title="Any platform Banking System" description="One Bank N solutions" />
            </Card>
          </div>
        </Col>
        <Col span={8}>
          <div className={classes.loginform}>
            <img alt="APBS Logo" src="/logo.png" />
            <Divider />
            <LoginForm />
          </div>
        </Col>
      </Row>
    </Page>
  );
};
