import jwt_decode from 'jwt-decode';
import { AuthStorageKey } from '../common/constants';

const getToken = () => {
  const rawToken = window.localStorage.getItem(AuthStorageKey);
  return rawToken ? JSON.parse(rawToken).access_token : null;
};

const getRefreshToken = () => {
  const rawToken = window.localStorage.getItem(AuthStorageKey);
  return rawToken ? JSON.parse(rawToken).refresh_token : null;
};

const setToken = (token: string) =>
  window.localStorage.setItem(AuthStorageKey, JSON.stringify(token));

const clearToken = () => window.localStorage.removeItem(AuthStorageKey);

const decodeIdentity = (token: string) => {
  const data: any = jwt_decode(token);
  return data.identity;
};

const isExpired = (token: string | null) => {
  if (token) {
    const data: any = jwt_decode(token);
    return data.exp <= Date.now();
  }

  return false;
};

const isTokenExpired = () => {
  const token = getToken();

  return isExpired(token);
};

const isRefreshTokenExpired = () => {
  const token = getRefreshToken();

  return isExpired(token);
};

const getUserIdentity = () => {
  const token = getToken();

  if (!token) {
    return null;
  }

  return decodeIdentity(token);
};

export {
  getToken,
  getRefreshToken,
  setToken,
  clearToken,
  isTokenExpired,
  isRefreshTokenExpired,
  getUserIdentity,
  decodeIdentity,
  isExpired,
};
