import { DoubleRightOutlined, FileDoneOutlined } from '@ant-design/icons';
import { Card, Drawer, Space, Typography, Pagination, Checkbox, Button, Spin } from 'antd';
import moment from 'moment';
import React, { FC, useState } from 'react';
import { useNotifications, useChangeNcStatus } from '../../api/notification';
import { PageInfo } from '../../pages/index';

const { Meta } = Card;
const { Text } = Typography;

interface NotificationProps {
  setVisible: (state: boolean) => void;
  visible: boolean;
}

const NoitfyDrawer: FC<NotificationProps> = ({ setVisible, visible }: NotificationProps) => {
  const [pageDetails, setPageDetail] = useState<PageInfo>({ page: 1, pageSize: 5 });
  const [isShowAll, setShowAll] = useState<boolean>(true);
  const {
    data: notices,
    isLoading,
    isError,
  } = useNotifications({
    page: pageDetails.page,
    pageSize: pageDetails.pageSize,
    type: isShowAll ? 'all' : 'unseen',
  });
  const statusHandle = useChangeNcStatus();

  if (isLoading) {
    return <Spin />;
  }

  if (isError) {
    return <p />;
  }

  const statusChangeHandler = (id: number, status: boolean) => {
    const nextState = status ? 'unseen' : 'seen';
    const params = { url: `/notification/${id}/${nextState}` };
    statusHandle.mutate(params);
  };

  const MetaDescription = ({ props }: any) => {
    const { isSeen, link, id } = props;

    return (
      <>
        <Space size="large">
          <span>{isSeen ? 'Seen' : 'UnSeen'}</span>
          <Button
            type="link"
            icon={<DoubleRightOutlined />}
            size="small"
            onClick={() => statusChangeHandler(id, isSeen)}
          >
            {isSeen ? 'Mark Unseen' : 'Mark Seen'}
          </Button>
          {link && (
            <span>
              <Button
                type="link"
                icon={<FileDoneOutlined />}
                href={link}
                target="_blank"
                rel="noopener noreferrer"
              >
                Reference
              </Button>
            </span>
          )}
        </Space>
      </>
    );
  };

  const NcTitle = ({ props }: any) => {
    const { isUrgent, created_at: createdAt, source, event } = props;

    return (
      <span>
        <Text code type={isUrgent ? 'danger' : 'success'}>
          {source}
        </Text>{' '}
        <Text code>{event}</Text> ({moment(createdAt).format('ddd, MMM Do YY, h:mm a')})
      </span>
    );
  };

  return (
    <>
      <Drawer
        title={
          <span>
            <Space size="large">
              Notifications
              <Checkbox defaultChecked={isShowAll} onChange={() => setShowAll(!isShowAll)}>
                Show All
              </Checkbox>
            </Space>
          </span>
        }
        placement="right"
        closable
        onClose={() => setVisible(false)}
        visible={visible}
        width="35%"
        destroyOnClose
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          {notices?.data.map((item: any) => (
            <Card key={item.id}>
              <NcTitle props={item} />
              <hr color="grey" />
              {item.message} <Meta description={<MetaDescription props={item} />} />
            </Card>
          ))}
          <Pagination
            size="small"
            current={pageDetails.page}
            pageSize={pageDetails.pageSize}
            onChange={(page: any, pageSize: any) => setPageDetail({ page, pageSize })}
            showSizeChanger
            pageSizeOptions={['4', '8', '12']}
            total={notices?.meta.total}
            hideOnSinglePage={notices?.meta.total === 0}
          />
        </Space>
      </Drawer>
    </>
  );
};

export { NoitfyDrawer };
