import { QueryClient } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false, staleTime: 1 * 6 * 1000 },
  },
});

const queryCache = queryClient.getQueryCache();

export { queryClient, queryCache };
