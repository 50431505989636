import { Layout, MenuTheme } from 'antd';
import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import { HeaderBanner } from '../Header/Header';
import { LayoutContainer } from '../LayoutContainer/LayoutContainer';
import { NavBar } from '../Menu/Menu';

interface LayoutProps {
  children: ReactNode;
  theme: MenuTheme;
}

const { Header, Content, Sider } = Layout;

export const PageLayout: FC<LayoutProps> = ({
  children,
  theme,
}: PropsWithChildren<LayoutProps>) => {
  return (
    <Layout>
      <ErrorBoundary>
        <Header
          style={{
            position: 'fixed',
            zIndex: 1,
            width: '100%',
            background: '#ffff',
          }}
        >
          <HeaderBanner />
        </Header>
      </ErrorBoundary>
      <Layout className="site-layout" style={{ marginTop: 70 }}>
        <Sider
          theme={theme}
          style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            left: 0,
          }}
        >
          <NavBar style={{}} theme={theme} />
        </Sider>
        <Content style={{ marginLeft: 200 }}>
          <ErrorBoundary>
            <LayoutContainer role="main" Tag="body">
              {children}
            </LayoutContainer>
          </ErrorBoundary>
        </Content>
      </Layout>
    </Layout>
  );
};
