import axios, { AxiosError, AxiosResponse } from 'axios';
import { getToken, getRefreshToken, setToken, clearToken } from './utils';

const SESSION_EXPIRED_STATUS_CODE = 401;

const client = axios.create({
  baseURL: process.env.REACT_APP_DOMAIN,
});

client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    try {
      const originalRequest = error.config;
      if (error.response.status === 401) {
        if (originalRequest.url === '/refresh') {
          clearToken();
          window.location.reload();
        }
        // eslint-disable-next-line promise/no-promise-in-callback
        client({
          url: '/refresh',
          method: 'POST',
          headers: {
            Authorization: `Bearer ${getRefreshToken()}`,
          },
        })
          .then((res) => {
            setToken(res.data.data);
            window.location.reload();
            return client(originalRequest);
          })
          .catch((e) => {
            // eslint-disable-next-line no-console
            console.log(e);
          });
      }
      return Promise.reject(error);
    } catch (e) {
      return Promise.reject(error);
    }
  }
);

const apiClient = (auth: boolean, { ...options }) => {
  const token = getToken();
  if (auth && token) {
    client.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  client.defaults.headers.common['X-Frame-Options'] = 'DENY';
  client.defaults.headers.common['X-Content-Type-Options'] = 'nosniff';

  const onSuccess = (response: AxiosResponse) => response;

  const onError = (error: AxiosError) => {
    if (error.response && error.response.status === SESSION_EXPIRED_STATUS_CODE) {
      // Navigate to Login screen
    }

    const errJson = error.toJSON();
    // eslint-disable-next-line no-console
    console.log('Error', errJson, error.message);
    return Promise.reject(error);
  };

  return client(options).then(onSuccess).catch(onError);
};

export { apiClient };
