import { useQuery, useMutation, useQueryClient } from 'react-query';
import { apiClient } from './index';

const getJwtInfo = async () => {
  const { data } = await apiClient(true, {
    url: `/settings/jwt-config`,
  });

  return data.data;
};

function useJwtInfo() {
  return useQuery('jwtInfo', getJwtInfo, {
    staleTime: 0,
  });
}

const updateJwtInfo = async (params: any) => {
  const { data } = await apiClient(true, {
    method: 'PATCH',
    url: `/settings/jwt-config`,
    data: params,
  });

  return data.data;
};

function useUpdateJwtInfo() {
  const queryClient = useQueryClient();
  return useMutation('updateJwtInfo', updateJwtInfo, {
    onSuccess: () => {
      queryClient.invalidateQueries('jwtInfo');
    },
  });
}

const getSystemInfo = async () => {
  const { data } = await apiClient(true, {
    url: `/settings/system-info`,
  });

  return data.data;
};

function useSystemInfo() {
  return useQuery('systemInfo', getSystemInfo, {
    staleTime: Infinity,
  });
}

const addSystemInfo = async (params: any) => {
  const { data } = await apiClient(true, {
    method: 'POST',
    url: `/settings/system-info`,
    data: params,
  });

  return data.data;
};

function useAddSystemInfo() {
  const queryClient = useQueryClient();
  return useMutation('addsystemInfo', addSystemInfo, {
    onSuccess: () => {
      queryClient.invalidateQueries('systemInfo');
    },
  });
}

const updateSystemInfo = async (params: any) => {
  const { data } = await apiClient(true, {
    method: 'PATCH',
    url: `/settings/system-info`,
    data: params,
  });

  return data.data;
};

function useUpdateSystemInfo() {
  const queryClient = useQueryClient();
  return useMutation('updateSystemInfo', updateSystemInfo, {
    onSuccess: () => {
      queryClient.invalidateQueries('systemInfo');
    },
  });
}

const getNoticeInfo = async () => {
  const { data } = await apiClient(true, {
    url: `/settings/notice-config`,
  });

  return data.data;
};

function useNoticeInfo() {
  return useQuery('noticeInfo', getNoticeInfo, {
    staleTime: Infinity,
  });
}

const addNoticeConfig = async (params: any) => {
  const { data } = await apiClient(true, {
    method: 'POST',
    url: `/settings/notice-config`,
    data: params,
  });

  return data.data;
};

function useAddNoticeConfig() {
  const queryClient = useQueryClient();
  return useMutation('addNoticeConfig', addNoticeConfig, {
    onSuccess: () => {
      queryClient.invalidateQueries('noticeInfo');
    },
  });
}

const updateNoticeInfo = async (params: any) => {
  const { data } = await apiClient(true, {
    method: 'PATCH',
    url: `/settings/notice-config`,
    data: params,
  });

  return data.data;
};

function useUpdateNoticeInfo() {
  const queryClient = useQueryClient();
  return useMutation('updateNoticeInfo', updateNoticeInfo, {
    onSuccess: () => {
      queryClient.invalidateQueries('noticeInfo');
    },
  });
}

export {
  useJwtInfo,
  useUpdateJwtInfo,
  useAddSystemInfo,
  useSystemInfo,
  useUpdateSystemInfo,
  useNoticeInfo,
  useAddNoticeConfig,
  useUpdateNoticeInfo,
};
