import { FileDoneOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { FC } from 'react';
import { FileDownloadURL } from '../../common/constants';

const fileLink = (name: string) => {
  return `${FileDownloadURL}${name}`;
};

const SampleFileList: FC = () => {
  const fileList = [
    {
      label: 'Admin Import Sample',
      link: fileLink('AdminImportSample.xlsx'),
    },
  ];

  const columns = [
    {
      title: 'File',
      dataIndex: 'label',
      key: 'label',
    },
    {
      title: 'URL',
      dataIndex: 'link',
      key: 'link',
      render: (value: any) => (
        <div>
          <Button
            type="link"
            icon={<FileDoneOutlined />}
            href={value}
            target="_blank"
            rel="noopener noreferrer"
          >
            Reference
          </Button>
        </div>
      ),
    },
  ];
  return (
    <Table
      showHeader={false}
      dataSource={fileList}
      columns={columns}
      size="small"
      pagination={false}
    />
  );
};

interface IFileModalProps {
  setVisible: (state: boolean) => void;
  visible: boolean;
}

export const SampleFilesModal: FC<IFileModalProps> = ({ setVisible, visible }: IFileModalProps) => {
  return (
    <Modal
      title="Download Sample File"
      width={400}
      visible={visible}
      onCancel={() => setVisible(false)}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      zIndex={1}
    >
      <SampleFileList />
    </Modal>
  );
};
